.chord {
    user-select: none;
    align-items: center;
    /* min-width: 150px; */
}

.draggable-chord {
    align-items: center;
    justify-content: center;
    margin: 4px;
    border-radius: 4px;
    width: calc(60vw/4 - 24px);
    height: fit-content;
    border: 1px solid black;
    background-color: white;
}

@media (max-width:960px) {
    .chord {
        min-width: 50px;
    }

    .chord h2 {
        font-size: 16px;
    }
    
    .draggable-chord {
        width: calc(80vw/4 - 24px);
    }

    .drag-handle {
        display: none;
    }
    
}

.chord-span {
    display: block;
    color: black;
    /* border-left: 1px solid; */
}

.chord-name {
    cursor: pointer;
}

.chord.selected span {
    background-color: lightcoral;
}


.chord.play-next span {
    background-color: lightgreen;
}

.grow {
    transition: ease-in-out 0.1s;
}

.grow:hover {
    scale: 1.1;
}
