.App {
  margin: 10vh auto 0 auto;
  text-align: center;
  width: 60%;
}

.circular-progress {
  height: 20vh !important;
  width: 20vh !important;
}

.circular-progress-wrapper {
  height: 40vh;
}

.main-slider {
  width: 50% !important;
}

@media (max-width:960px) {
  .App {
    margin: 5vh auto 0 auto;
    width: 80%;
  }

  .acknowledgement-modal {
    font-size: 10px;
  }

  .main-slider {
    width: 100% !important;
  }

  .circular-progress-wrapper {
    height: 30vh !important;
  }

  .circular-progress {
    height: 15vh !important;
    width: 15vh !important;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
